<template>
  <article class="appointment-scheduling appointment-scheduling--user">
    <transition
      name="fade"
      mode="out-in"
    >
      <progress-tracker v-if="isProgressTracker" />
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <top-bar v-if="isTopBar" />
    </transition>

    <transition
      name="fade"
      mode="out-in"
      @before-leave="beforeLeave"
      @enter="enter"
      @after-enter="afterEnter"
    >
      <router-view />
    </transition>
  </article>
</template>

<script>
import ProgressTracker from '@/components/appointmentScheduling/ProgressTracker.vue';
import TopBar from '@/components/appointmentScheduling/TopBar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserScheduling',

  components: {
    ProgressTracker,
    TopBar,
  },

  data() {
    return {
      prevHeight: 0,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner', 'medicalSpecialties']),
    ...mapGetters('ui', ['progressTracker']),

    isProgressTracker() {
      return this.$route.name !== 'UserSchedulingSummary';
    },

    isTopBar() {
      return this.progressTracker
        .some((step) => (step.step === 2 || step.step === 3) && step.isCurrent);
    },
  },

  watch: {
    '$route.name': {
      deep: false,
      immediate: true,
      handler(route) {
        this.resetProgressTrackerStep();

        if (
          this.progressTracker
            .some((item) => item.route.logged === route)
        ) {
          const { step } = this.progressTracker
            .find((item) => item.route.logged === route);

          this.setCurrentProgressTrackerStep(step);
        }
      },

      medicalSpecialties: {
        deep: true,
        immediate: true,
        handler(value) {
          if (value.length === 0) {
            this.fetchMedicalSpecialties();
          }
        },
      },
    },
  },

  methods: {
    ...mapActions('ui', ['setCurrentProgressTrackerStep', 'resetProgressTrackerStep']),

    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },

    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;
      setTimeout(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
};
</script>
